import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'

import * as s from '../pages/news.module.scss'

const DigitalEngineeer = () => {
  return (
    <>
      <SEO
        title="Digital engineer: A new indispensable role on the job site"
        description="Global trends and data-driven development in different industries dictate the need for permanent change and finding ways to be more effective for stakeholders and project participants. This is reflected in many types of business through new technologies, new collaboration processes, new org charts and new roles. Making information and processes digital makes it possible to do more with less time and resources. The digital engineer is one of these new heroes."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Digital engineer: A new indispensable role on the job site
        </Typography>
        <Typography variant="h2" size={24} mb={16}>
          Every company needs a digital transformation. No company should do it
          alone.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/digital-engineer/image-1.jpg"
            alt="The digital engineer becomes the center of all data processes"
            title="The digital engineer becomes the center of all data processes"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            The digital engineer becomes the center of all data processes
          </Typography>
        </div>
        <Typography variant="body1">
          Global trends and data-driven development in different industries
          dictate the need for permanent change and finding ways to be more
          effective for stakeholders and project participants. This is reflected
          in many types of business through new technologies, new collaboration
          processes, new org charts and new roles. Some old roles are no longer
          needed; new ones come to the fore. These new roles often replace a
          list of people in the previous workflow. Making information and
          processes digital makes it possible to do more with less time and
          resources.
        </Typography>
        <Typography variant="body1">
          The digital engineer is one of these new heroes.
        </Typography>
        <Typography variant="h2" size={24} mb={16}>
          What is a digital engineer?
        </Typography>
        <Typography variant="body1">
          Much has been written about innovations in design and office
          collaboration in construction, but now is the right time for these
          advances to reach the job site. Digital engineers are the center of
          competence for digital technologies and using BIM on site. They
          cooperate with design and project offices, make implementations, train
          employees and also perform new functions that are needed at a specific
          site. Typically, one digital engineer is assigned to each site.
        </Typography>
        <Typography variant="body1">
          In previous years, this role may also have been known as a VDC manager
          or BIM coordinator for construction. But responsibilities, authorities
          and most importantly, expectations for performance improvement are
          much higher for digital engineers. They become the center of all data
          processes. Their main metrics are not only doing everyday routines but
          also making general construction processes better.
        </Typography>
        <Typography variant="h2" size={24} mb={16}>
          Why a digital engineer is so important
        </Typography>
        <Typography variant="body1">
          A digital engineer becomes the leading innovator at a construction
          site. They immerse themselves in the on-site processes and offer the
          most effective solution. They are helpers for the team, and they
          develop the team: it is essential to change the team’s minds about,
          and increase the general understanding of, the technologies being used
          — obtaining the trust that these technologies can do part of a human’s
          work.
        </Typography>
        <Typography variant="body1">
          The digital engineer often assists the project manager and
          participates in all meetings. With the help of actual construction BIM
          models, they show a visual picture of the site, make simulations,
          calculate volumes, provide up-to-date schedules and become the first
          helper in decision-making. They also take the necessary measurements
          from the 3D model during the meeting. It was impossible 15 years ago,
          inconvenient ten years ago and not so fast five years ago, but it is
          natural now with advanced mobile and cloud technologies.
        </Typography>
        <Typography variant="body1">
          One of the main things that digital engineers can do is unite the
          teams that are working remotely. A digital depiction of the site has
          become accessible anytime for all managers, and it doesn’t matter
          where they are. Modern monitoring services such as Photo 360, drone
          survey, laser scanning and AR/VR provide visual clarity of the issues
          raised, a common understanding of these issues and a quick solution.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/digital-engineer/image-2.jpg"
            alt="SIGNAX INSPECTION offers remote construction visual control and BIM
            to as-built comparison"
            title="SIGNAX INSPECTION offers remote construction visual control and BIM
            to as-built comparison"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            SIGNAX INSPECTION offers remote construction visual control and BIM
            to as-built comparison
          </Typography>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          How to find a digital engineer
        </Typography>
        <Typography variant="body1">
          Two places to find a digital engineer are the construction industry or
          IT departments. This person must have the proper fundamental
          education, either a bachelor’s or master’s degree. Young specialists
          are usually faster for everything new, but it is also a good career
          opportunity for existing engineers. Typically, this requires
          experience participating in real projects in design or construction.
          It’s much easier to change workflows and establish a new effective
          structure of digital interaction when you previously worked inside the
          old one and know all its problems and challenges well.
        </Typography>
        <Typography variant="body1">
          At the same time, digital engineers should understand which modern
          technologies provide real profit on a construction site. They need to
          be fast learners of new solutions, have a broad outlook of the
          existing software/services and be able to see the trends with emerging
          technologies. An IT background will be crucial as they will need to
          interact with the local IT team, vendors, IT consultants and service
          providers.
        </Typography>
        <Typography variant="body1">
          A promotion to being the digital engineer on site can be a step up for
          BIM coordinators in the design office. They may already have a passion
          for automating processes and can get new motivation for
          self-realization on site where the level of innovation is much lower.
          The same is applicable for most active office engineers. They may be
          tired of routine and seek to lead new internal corporate projects.
          Digital construction implementation can be one of those projects.
        </Typography>
        <Typography variant="body1">
          Another variant is to outsource a digital engineer. Consulting
          companies have started to provide a digital engineer for hire. The
          main advantage in this case is that you will get not only expertise
          but also the experience from wins and losses on other projects.
          Innovative people know that many questions have no answer until you
          try. Not every company can afford to try on a hot project. SIGNAX is
          one company that offers this digital engineer service.
        </Typography>
        <Typography variant="h2" size={24} mb={16}>
          List of responsibilities
        </Typography>
        <Typography variant="body1">
          Let’s break down the responsibilities of a digital engineer, including
          core things used by leading companies today. At the same time, the
          choice of technologies (BIM uses) always depends on the project and
          its goals.
        </Typography>
        <List>
          <li>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Checking BIM data from design companies
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              Digital data on site can work if we are confident in its quality
              and completeness. A digital engineer checks clashes and needed
              parameters of elements according to the corporate classification
              system (WBS, CBS, etc.).
            </Typography>
          </li>
          <li>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Volumes calculation from the model
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              This can be used for budgeting or construction schedule creation.
              All these operations don’t need the work of cost and procurement
              engineers anymore. The calculations can be made in online mode and
              dramatically reduce the human factor.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Merging schedules and BIM models (4D)
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              4D came to the construction industry many years ago but schedules
              were usually made by design companies. As the general contractor
              has a financial incentive for being on time, it is much better to
              manage the schedule directly on site.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Site management using the construction BIM model
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              This includes the calculation of works done, completed and
              accepted. The precise numbers are always in the system and can be
              used by project managers and the supervision team. You can easily
              see the progress of any date and pay contractors for the exact
              amount of work.
            </Typography>
          </li>
          <li>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Photo 360, laser scanning and drone surveys on site
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              These are good examples of obtaining digital data and are
              priceless for reducing the time and increasing the quality of
              construction work. It helps to have a clear understanding of the
              current status and the history of the process. Photogrammetry
              helps in all kinds of engineering tasks
            </Typography>
          </li>
          <li>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Development of as-built model and operation stage digital twin of
              the facility
            </Typography>
            <Typography variant="body1" style={{ textIndent: 0 }}>
              A project can have many changes since it was first designed.
              Digital operations also need up-to-date data. It’s much easier to
              collect data during construction. Digital assets can increase the
              cost of the facility.
            </Typography>
          </li>
        </List>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/digital-engineer/image-3.jpg"
            alt="Mobile technologies start playing a great role in construction projects"
            title="Mobile technologies start playing a great role in construction projects"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Mobile technologies start playing a great role in construction
            projects
          </Typography>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          Conclusion
        </Typography>
        <Typography variant="body1">
          The construction industry is on a path to improvement. Moreover, each
          company has the goal of paperless construction in the near future.
          Digital technologies must play a significant role in this process.
          Companies need reliable guidance to deal with working with and
          managing the data, changing the workflows and the culture of
          digitalizing for every project stakeholder. This has led to the
          digital engineer becoming indispensable and a leader in providing the
          valuable collaboration on the project site.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default DigitalEngineeer
